import React from 'react';
import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 0, // Background layer
        }}
      >
        <source src="/videoplayback.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content Wrapper */}
      <div
        style={{
          position: 'relative', // Keeps content above the video
          zIndex: 1, // Foreground layer
          color: 'white',
          textAlign: 'center',
        }}
      >
        {/* Hero Title */}
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          style={{
            paddingTop: '40vh',
            fontSize: '4rem',
          }}
        >
          Welcome to OptimalRp
        </motion.h1>

        {/* Hero Description */}
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          style={{
            fontSize: '1.2rem',
            marginTop: '20px',
          }}
        >
          Join our community on{' '}
          <a
            href="https://discord.gg/optimalrp"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#7289da',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            Discord
          </a>
        </motion.p>
      </div>
    </div>
  );
};

export default Hero;
