import React from 'react';

// Import Font Awesome Icons
import { FaDiscord, FaTwitter, FaInstagram, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <div style={{ padding: '20px', background: '#333', color: 'white', textAlign: 'center' }}>
      <p>&copy; 2024 bobatari | All Rights Reserved</p>
      <div style={{ marginTop: '10px' }}>
        <a
          href="https://discord.gg/your-discord-link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#00e0ff', margin: '0 10px', fontSize: '20px' }}
        >
          <FaDiscord />
        </a>
        <a
          href="https://twitter.com/your-twitter-handle"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#00e0ff', margin: '0 10px', fontSize: '20px' }}
        >
          <FaTwitter />
        </a>
        <a
          href="https://instagram.com/your-instagram-handle"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#00e0ff', margin: '0 10px', fontSize: '20px' }}
        >
          <FaInstagram />
        </a>
        <a
          href="mailto:contact@yourwebsite.com"
          style={{ color: '#00e0ff', margin: '0 10px', fontSize: '20px' }}
        >
          <FaEnvelope />
        </a>
      </div>
    </div>
  );
};

export default Footer;

