import React from 'react';
import { motion } from 'framer-motion';

const projects = [
  { id: 1, title: 'FiveM', image: 'https://optimalrp.com/img/orp5m.png', link: 'https://optimalrp.com/fivem' },
  { id: 2, title: 'RedM', image: 'https://optimalrp.com/img/orpRm.png', link: 'https://optimalrp.com/redm' },
  { id: 3, title: 'Minecraft', image: 'https://optimalrp.com/img/orpMm.png', link: 'https://optimalrp.com/minecraft' },
  { id: 4, title: 'Rust', image: 'https://optimalrp.com/img/orpRUm.png', link: 'https://optimalrp.com/rust' },
];

const Portfolio = () => {
  return (
    <div style={{ padding: '50px', textAlign: 'center' }}>
      <h2>Our Servers</h2>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap', // Allows items to wrap on smaller screens
          justifyContent: 'center', // Centers the items
          gap: '20px', // Space between items
          marginTop: '20px',
        }}
      >
        {projects.map((project) => (
          <a
            key={project.id}
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              width: 'calc(50% - 20px)', // 50% width for tablets and larger screens
              maxWidth: '400px', // Max width for cards
              flex: '1 1 calc(50% - 20px)', // Makes it responsive
            }}
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              style={{
                border: '1px solid #ddd',
                padding: '20px',
                borderRadius: '10px',
                overflow: 'hidden',
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
            >
              <img
                src={project.image}
                alt={project.title}
                style={{ width: '100%', height: '150px', objectFit: 'cover' }}
              />
              <h3>{project.title}</h3>
            </motion.div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;


