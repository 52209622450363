import React, { useState } from 'react';
import { motion } from 'framer-motion';

const About = () => {
  const [selectedApp, setSelectedApp] = useState(null); // To control which modal is open
  const [formData, setFormData] = useState({
    email: '',
    discordID: '',
    inGameName: '',
    age: '',
    backstory: '',
    experience: '',
  }); // Form data

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      username: `${selectedApp.title} Application`,
      content: `**Email:** ${formData.email}
**Discord ID:** ${formData.discordID}
**In-Game Name:** ${formData.inGameName}
**Age:** ${formData.age}
**Character Backstory:** ${formData.backstory}
**Roleplay Experience:** ${formData.experience}`,
    };

    try {
      const response = await fetch('https://discord.com/api/webhooks/1296092461294161930/1Bzs20di77TAPh2ptl-ZC9TkANJk9ufQBBx6BiaWksW-gKtCTy4CCuCLbOz-dARgIS0C', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('Application submitted successfully!');
        setFormData({
          email: '',
          discordID: '',
          inGameName: '',
          age: '',
          backstory: '',
          experience: '',
        }); // Reset form
        setSelectedApp(null); // Close modal
      } else {
        alert('Failed to submit the application. Please try again.');
      }
    } catch (error) {
      alert('An error occurred while submitting the application.');
      console.error(error);
    }
  };

  return (
    <div>
      {/* Applications Section */}
      <h2 style={{ marginTop: '10px', textAlign: 'center' }}>
        Please click below to submit an application for whitelist.
      </h2>
      <div style={{ display: 'flex', width: '100%', background: '#333', color: 'white', padding: '20px 0' }}>
        {applications.map((app) => (
          <div
            key={app.id}
            onClick={() => setSelectedApp(app)}
            style={{
              flex: 1,
              textAlign: 'center',
              borderRight: '1px solid #444',
              padding: '20px',
              cursor: 'pointer',
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.5, delay: app.id * 0.1 }}
            >
              <img
                src={app.icon}
                alt={app.title}
                style={{ width: '50px', height: '50px', marginBottom: '10px' }}
              />
              <h3>{app.title}</h3>
              <p>{app.description}</p>
            </motion.div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {selectedApp && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              background: 'white',
              padding: '20px',
              borderRadius: '10px',
              width: '400px',
            }}
          >
            <h2>Apply for {selectedApp.title}</h2>
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: '10px' }}>
                <label>Email Address:</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                  style={{ width: '100%', padding: '5px', marginTop: '5px' }}
                />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label>Discord ID:</label>
                <input
                  type="text"
                  value={formData.discordID}
                  onChange={(e) => setFormData({ ...formData, discordID: e.target.value })}
                  required
                  style={{ width: '100%', padding: '5px', marginTop: '5px' }}
                />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label>In-Game Name:</label>
                <input
                  type="text"
                  value={formData.inGameName}
                  onChange={(e) => setFormData({ ...formData, inGameName: e.target.value })}
                  required
                  style={{ width: '100%', padding: '5px', marginTop: '5px' }}
                />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label>Age:</label>
                <input
                  type="number"
                  value={formData.age}
                  onChange={(e) => setFormData({ ...formData, age: e.target.value })}
                  required
                  style={{ width: '100%', padding: '5px', marginTop: '5px' }}
                />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label>Character Backstory:</label>
                <textarea
                  value={formData.backstory}
                  onChange={(e) => setFormData({ ...formData, backstory: e.target.value })}
                  required
                  style={{ width: '100%', padding: '5px', marginTop: '5px', height: '80px' }}
                />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label>Roleplay Experience:</label>
                <textarea
                  value={formData.experience}
                  onChange={(e) => setFormData({ ...formData, experience: e.target.value })}
                  required
                  style={{ width: '100%', padding: '5px', marginTop: '5px', height: '80px' }}
                />
              </div>
              <button
                type="submit"
                style={{ padding: '10px 20px', background: '#333', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => setSelectedApp(null)}
                style={{
                  padding: '10px 20px',
                  background: '#00e0ff',
                  color: '#333',
                  border: 'none',
                  borderRadius: '5px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// Data for Applications
const applications = [
  { id: 1, title: 'FiveM', icon: 'https://optimalrp.com/img/emblem-fiveM.png', description: 'Apply for our FiveM server.' },
  { id: 2, title: 'RedM', icon: 'https://optimalrp.com/img/emblem-redM.png', description: 'Join our RedM adventures.' },
  { id: 3, title: 'Minecraft', icon: 'https://optimalrp.com/img/emblem-mc.png', description: 'Explore worlds in Minecraft.' },
  { id: 4, title: 'Rust', icon: 'https://optimalrp.com/img/emblem-Rust.png', description: 'Survive in Rust with us.' },
];

export default About;
