import React from 'react';
import Hero from './components/Hero';
import Portfolio from './components/Portfolio';
import About from './components/About';
import Footer from './components/Footer';
import Applications from './components/Applications';

function App() {
  return (
    <div>
      <Hero />
      <About />
      <Portfolio />
      <Applications />
      <Footer />
    </div>
  );
}

export default App;

