import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <div>
      {/* About Section */}
      <div style={{ padding: '50px', background: '#f4f4f4' }}>
        <motion.h2
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          style={{ textAlign: 'center' }}
        >
          About Us
        </motion.h2>
        <p style={{ marginTop: '20px', textAlign: 'center' }}>
          We are a creative team working to create the best possible servers for multiple types of games.
        </p>

      </div>

    
    </div>
  );
};


export default About;
